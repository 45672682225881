<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>班级或小组：</span>
        <!-- <el-select v-model="class_id" @change="getData" style="width:300px">
          <el-option v-for="(item,idx) in myClasses" :key="idx" filterable
                     :label="item.upname+'-'+item.name+(item.type=='bj'?'[班级]':'[小组]')" :value="item.id"></el-option>
        </el-select> -->

        <tchtree v-model="class_id" @change="getData"></tchtree>

        <el-button v-if="$store.state.pmid == 214 || $store.state.pmid == 221"
                   icon="el-icon-download"
                   size="small"
                   type="primary"
                   @click="exportSX" style="margin-left: 10px;">导出
        </el-button>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生实习</el-breadcrumb-item>
          <el-breadcrumb-item>实习统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent" style="width: 100%;height: calc( 100% - 80px );">
      <div id="chartBox" style="width: 100%;height: 500px;"></div>

      <div style="padding:50px">
        <table class="dttable">
          <tr>
            <td class="tdheader" style="width:50px">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">实习率</td>
          </tr>

          <template v-if="dataList.length">
            <tr v-for="(item,idx) in dataList" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.class_name }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.yx_name }}</td>
              <td>{{ item.sx_rate }}%</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5" align="center">暂无数据</td>
          </tr>

        </table>
      </div>
    </div>


  </div>
</template>

<script>
import util from "../../../utils/util.js"
import tchtree from '../../com/tchTree.vue'
import {njsyxx_statistics_export_excel} from "../../../../public/js/Export2Excel";

export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: "",
      sign_date: util.formatDate(new Date()),
      dataList: [],
      sxqxArr: [],
    }
  },
  mounted() {

    this.getAllPickerVal().then(rr => {
      this.getMyClasses()
    })
  },
  methods: {
    getAllPickerVal() {
      return new Promise(resolve => {
        //巡访形式&&巡访情况
        this.$http.post("/api/sys_param_list", {param_type: "'SHIXI_STATUS'"}).then((res) => {
          let sxqxArr = []
          res.data.map((item, index, arr) => {
            if (item.param_type == 'SHIXI_STATUS') {
              sxqxArr.push(item)
            }
          })
          this.sxqxArr = sxqxArr
          resolve()
        })
      })
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getData()
      })
    },
    getData() {
      this.$http.post("/api/stu_sxqx_statistics", {class_id: this.class_id}).then(res => {
        res.data && res.data.map(a => {
          if (a.sx_rate) {
            a.sx_rate = this.$keepTwoDecimal(parseFloat(a.sx_rate) * 100)
          }
          a.value = a.sx_rate
          a.class_name = a.NAME
          a.NAME += a.username
        })
        this.dataList = res.data
        this.initChart()
      })
    },
    initChart() {
      let _this = this
      //////console.log(111,this.dataList)
      let dataAxis = this._lo.map(this.dataList, 'NAME')
      let data = this._lo.map(this.dataList, 'sx_rate')


      let option = {
        title: {
          text: '班级/小组实习率(%)',
        },
        grid: {containLabel: true,},
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            // //////console.log(101101,_this.sxqxArr,params[0].data);
            let paramsData = params[0].data
            if (params.length > 0) {
              var result = params[0].name + "<br>";
              result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span> 实习率：${params[0].value}%</br>`
              // params.forEach(function (item) {
              //   //////console.log(104104,item.value)
              // });
              result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span> 总人数：${paramsData.qb_count} 人</br>`
              _this.sxqxArr.map(a => {
                let name = `count_${a.param_value}`
                if (paramsData[name] > -1) {
                  if (a.param_value == 0) {
                    result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#f56c6c;"></span><span style="color: #f56c6c;"> ${a.param_desc}：${paramsData[name]} 人</span></br>`
                  } else {
                    result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span> ${a.param_desc}：${paramsData[name]} 人</br>`
                  }
                }
              })
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {inside: false, color: '#000', alignWithLabel: true, rotate: 60},
          axisTick: {show: true},
          axisLine: {show: true},
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: {show: true},
          axisTick: {show: true},
          axisLabel: {color: '#000'}
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,

            itemStyle: {
              color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: this.dataList,

            label: {
              show: true,
              position: 'inside',
              // formatter:function(params){ //标签内容
              //   return  params.value + '%'
              // },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)
    },
    exportSX() {
      this.$http.post('/api/export_statistics_xls', {
        class_id: this.class_id,
        type: 'sx'
      }).then(res => {
        let excelData = [
          ['班级实习率汇总'],
          ['序号', '系部', '班级', '班主任', '实习率']
        ]
        res.data && res.data.map((r, index) => {
          if (r.sx_rate) {
            r.sx_rate = this.$keepTwoDecimal(parseFloat(r.sx_rate) * 100)
          }
          excelData.push([
            ++index,
            r.yx_name,
            r.NAME,
            r.username,
            r.sx_rate + '%',
          ])
        })
        njsyxx_statistics_export_excel(excelData, 5)
      })
    },
  },

};
</script>

